@media print {
  .printStyles_body * {
    visibility: hidden;
  }

  .printStyles_noPrint {
    display: none;
  }

  .printStyles_printContent,
  .printStyles_printContent * {
    visibility: visible;
  }

  .printStyles_printContent {
    position: absolute;
    left: 0;
    top: 0;
  }
}
