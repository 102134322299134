.newsCard {
  flex: 0 0 auto; /* Prevent shrinking */
  width: 240px; /* Card width, adjust based on design */
}

.truncateTitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Ensures exactly 2 lines are shown */
  height: calc(1.2em * 1); /* Assuming line height of 1.2 */
  line-height: 1.2;
}

.spanSmallText {
  font-size: 12px;
}
