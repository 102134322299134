@import "node_modules/bootstrap/scss/functions";

$theme-colors: (
  "primary": #007bff,
  "secondary": #6c757d,
  "success": #28a745,
  "info": #17a2b8,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40,
  /* Custom Colors */
  "spaceExploration": #090c9b,
  "blackBtn": #000000,
  "whiteBtn": #FFFFFF,
  "desiredDawn": #D9D9D9,
  "whiteSmoke": #f5f5f5,
  "veilingWaterfall": #D7E9FF,
  "azure": #EDFEFF,
  
  /* Accent Colors */
  "skyBlue": #87CEEB,
  "electricBlue": #007BFF,
  "hotPink": #FF4081,
  "neonGreen": #39FF14,
  "lavender": #C77DFF,
  "aliceBlue": #F2F8FF,
  'ghostWhite': #FAFAFF,
  'corbeau': #0c091e,
  'eigengrau':#14191c,
  'foundationWhite': #ececfc,
  'sliceHeaven': #0031e2,
  'quartz': #dbdbf3,
  'opulentBlue': #1C49EE,
  'cornflowerBlue': #6c8cff,
  'darkKnight': #19162B,
  'wetAsphalt':#9F9DAB,
  'icePalace': #32FF78,
  'divaPink': #FF4081,
  'vibrantBlue': #1444f0
);

:root {
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  /* Custom Colors */
  --spaceExploration: #090c9b;
  --blackBtn: #000000;
  --whiteBtn: #FFFFFF;
  --desiredDawn: #D9D9D9;
  --whiteSmoke: #F5f5f5;
  --veilingWaterfall: #D7E9FF;
  --azure: #EDFEFF;

  /* Accent Colors */
  --skyBlue: #87CEEB;
  --electricBlue: #007BFF;
  --hotPink: #FF4081;
  --neonGreen: #39FF14;
  --lavender: #C77DFF;
  --aliceBlue: #F2F8FF;
  --ghostWhite: #FAFAFF;
  --corbeau: #0c091e;
  --eigengrau: #14191c;
  --foundationWhite: #ececfc;
  --sliceHeaven: #0031e2;
  --quartz: #dbdbf3;
  --opulentBlue: #1C49EE;
  --cornflowerBlue: #6c8cff;
  --darkKnight: #19162B;
  --wetAsphalt: #9F9DAB;
  --icePalace: #32FF78;
  --divaPink: #FF4081;
  --vibrantBlue: #1444f0;
}

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/bootstrap";
