.artistCard {
  width: 100%;
  padding-top: 100%; /* Maintains square aspect ratio */
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    padding-top: 80%; /* Slightly reduce height for tablets and below */
    border-radius: 0.4rem; /* Slightly smaller border radius */
  }

  @media (max-width: 480px) {
    padding-top: 70%; /* Further reduce height for small mobile screens */
    border-radius: 0.3rem;
  }
}

.artistImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.artistName {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.4rem;
  /* background: rgba(0, 0, 0, 0.6); */
  color: white;
  font-size: 0.9rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Adjust font size for tablets */
    padding: 0.3rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem; /* Adjust font size for smaller mobile screens */
    padding: 0.2rem;
  }
}
