/* Define CSS Variables for Light and Dark Modes */
:root {
  /* Footer Background & Text */
  --footer-bg-light: #fafaff; /* Light mode background */
  --footer-text-light: #14191c; /* Light mode text color */
  --footer-bg-dark: #14191c; /* Dark mode background */
  --footer-text-dark: #fafaff; /* Dark mode text color */
  --footer-border-light: #dcdcdc; /* Light mode border color */
  --footer-border-dark: #494949; /* Dark mode border color */
  --footer-link-hover-light: #666666; /* Light mode link hover color */
  --footer-link-hover-dark: #999999; /* Dark mode link hover color */
  /* Link */
  --nav-link-text-light: #14191c; /* Light mode text color */
  --nav-link-text-dark: #fafaff; /* Dark mode text color */
  --nav-link-hover-light: #666666; /* Light mode hover color */
  --nav-link-hover-dark: #999999; /* Dark mode hover color */
  --nav-link-border-light: #14191c; /* Light mode border */
  --nav-link-border-dark: #fafaff; /* Dark mode border */
}

/* Footer CSS */
.AppFooter {
  background-color: var(--footer-bg-light);
  color: var(--footer-text-light);
  margin: 0px !important;
  padding: 0px !important;
}

/* Dark mode styles */
[data-bs-theme="dark"] .AppFooter {
  background-color: var(--footer-bg-dark);
  color: var(--footer-text-dark);
  margin: 0px !important;
  padding: 0px !important;
}

.footerLink {
  display: block; /* Make the links block elements */
  margin-bottom: 1.5rem; /* Space between links */
  color: var(--footer-text-light);
  text-decoration: none; /* Remove underline */
}

/* Dark mode link color */
[data-bs-theme="dark"] .footerLink {
  color: var(--footer-text-dark);
}

.footerLink:hover {
  color: var(--footer-link-hover-light);
}

/* Dark mode link hover color */
[data-bs-theme="dark"] .footerLink:hover {
  color: var(--footer-link-hover-dark);
}

.navLink {
  display: inline-flex; /* Center text within circle */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  width: 24px; /* Set fixed width */
  height: 24px; /* Set fixed height (same as width for a circle) */
  border-radius: 50%; /* Circular shape */
  text-decoration: none; /* Remove underline */
  color: var(--nav-link-text-light); /* Default text color */
  font-size: 14px; /* Adjust font size as needed */
  transition: all 0.3s ease; /* Smooth transition */
}

.navLinkAlphabet {
  display: inline-flex; /* Center text within circle */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  width: 24px; /* Set fixed width */
  height: 24px; /* Set fixed height (same as width for a circle) */
  border-radius: 50%; /* Circular shape */
  text-decoration: none; /* Remove underline */
  color: var(--nav-link-text-light); /* Default text color */
  font-size: 14px; /* Adjust font size as needed */
  transition: all 0.3s ease; /* Smooth transition */
  font-weight: normal;
}

.navLinkAlphabet:hover {
  background-color: #14191c; /* Dark background on hover */
  color: #ffffff; /* White text color on hover */
  border-radius: 50%; /* Keep circular shape */
}

/* Dark mode link color */
[data-bs-theme="dark"] .navLink {
  color: var(--nav-link-text-dark); /* Dark mode text color */
}

.navLink:hover {
  background-color: #14191c; /* Dark background on hover */
  color: #ffffff; /* White text color on hover */
  border-radius: 50%; /* Keep circular shape */
}

/* Dark mode hover effect */
[data-bs-theme="dark"] .navLink:hover {
  background-color: #666666; /* Darker background on hover in dark mode */
  color: #ffffff; /* White text color on hover */
}
