.largeVideoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.largeVideoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.smallVideoContainer {
  position: relative;
  padding-bottom: 100%; /* 1:1 aspect ratio for small videos */
  height: 0;
  overflow: hidden;
}

.smallVideoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
