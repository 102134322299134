.col-12-5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;

  @media (max-width: 991px) {
    flex: 0 0 100%; /* Full width on smaller screens */
    max-width: 100%;
  }
}

.col-75 {
  flex: 0 0 75%;
  max-width: 75%;

  @media (max-width: 991px) {
    flex: 0 0 100%; /* Full width on smaller screens */
    max-width: 100%;
  }
}
