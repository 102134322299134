.navLink {
  display: inline-flex; /* Center text within circle */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  width: 25px; /* Set fixed width */
  height: 25px; /* Set fixed height (same as width for a circle) */
  border-radius: 50%; /* Circular shape */
  text-decoration: none; /* Remove underline */
  color: var(--nav-link-text-light); /* Default text color */
  font-size: 15px; /* Adjust font size as needed */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Dark mode link color */
[data-bs-theme="dark"] .navLink {
  color: var(--nav-link-text-dark); /* Dark mode text color */
}

.navLink:hover {
  background-color: var(--eigengrau); /* Dark background on hover */
  color: #ffffff; /* White text color on hover */
  border-radius: 50%; /* Keep circular shape */
}

/* Dark mode hover effect */
[data-bs-theme="dark"] .navLink:hover {
  background-color: #666666; /* Darker background on hover in dark mode */
  color: #ffffff; /* White text color on hover */
}

/* new css  */
.genreButton {
  padding: 0.5rem 1rem;
  border: 2px solid black;
  border-radius: 0.5rem;
  font-weight: 600;
}

.dropdownSearch {
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
}

.dropdownList {
  max-height: 200px;
  overflow-y: auto;
  min-height: 150px;
}

.noGenres {
  padding: 8px 12px;
  text-align: center;
  color: gray;
  font-size: 0.9rem;
}

.navLink {
  white-space: nowrap;
}
