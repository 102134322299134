.alphabetNavContainer {
  width: 100%;
}

.scrollContainer {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of buttons to a new line */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.scrollContainer::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}

.navButton {
  background-color: transparent;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
}

.navButton:hover {
  background-color: black;
  color: white; /* Optional: Change text color on hover */
}
