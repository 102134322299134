/* ErrorPage.module.css */
.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  max-height: 100vh;
}

.errorPage img {
  width: 40%;
  height: auto;
  margin-bottom: 1rem;
}

.errorPage p {
  margin-top: 1rem;
  font-size: 2rem;
  line-height: 130%;
}

.errorMsg {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-weight: bold;
  gap: 1rem;
}

.btn {
  width: fit-content;
  border: none;
  padding: 5px 10px;
  background: #bd1d3d;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}
