.content {
  line-height: 1.5;
}

.small {
  font-size: 0.8rem;
}

.medium {
  font-size: 1rem;
}

.large {
  font-size: 1.2rem;
}

.error {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noLyrics {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  text-align: right;
}
